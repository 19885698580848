import React, { useEffect, useState } from "react";
import { getImageDownloadURL } from "../apis/signals";
import { Row, Col } from "antd";
interface IProps {
    imageRef: string;
}

export const TickerInformation: React.FC<IProps> = ({ imageRef }) => {
    const [imageURL, setImageURL] = useState<string | undefined>(undefined);
    useEffect(() => {
        getImageDownloadURL(imageRef, handleError, handleSuccess);
    }, [imageRef]);

    const handleSuccess = (image: string) => {
        setImageURL(image);
    };
    const handleError = (error: any) => {
        setImageURL("");
    };

    return (
        <div>
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <img src={imageURL} alt="stock-chart" />
                </Col>
            </Row>
        </div>
    );
};
