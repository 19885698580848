import React from "react";
import { routerType } from "../routes.types";
import About from "./About";
import Home from "./Home";
import ErrorPage from "./Error";
import TickerInformationWrapper from "./TickerInformation";
import PrivacyPolicy from "./PrivacyPolicy";
import { TOS } from "./TOS";
import DisclaimerPage from "./Disclaimer";
const pagesData: routerType[] = [
    {
        key: "home",
        path: "",
        element: <Home />,
        title: "home",
    },
    {
        key: "about",
        path: "about",
        element: <About />,
        title: "about",
    },
    {
        key: "ticker",
        path: "stock/:ticker",
        element: <TickerInformationWrapper />,
        title: "Stock",
    },
    {
        key: "privacy-policy",
        path: "privacy-policy",
        element: <PrivacyPolicy />,
        title: "privacy-policy",
    },
    {
        key: "terms-of-service",
        path: "terms-of-service",
        element: <TOS />,
        title: "terms-of-service",
    },
    {
        key: "disclaimer",
        path: "disclaimer",
        element: <DisclaimerPage />,
        title: "disclaimer",
    },
    {
        key: "error",
        path: "*",
        element: <ErrorPage />,
        title: "error",
    },
];

export default pagesData;
