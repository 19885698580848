import React, { useState } from "react";
import { Card, Button } from "antd";

const DisclaimerCard = () => {
    const [showDisclaimer, setShowDisclaimer] = useState(true);

    const handleClose = () => {
        setShowDisclaimer(false);
    };

    return (
        <>
            {showDisclaimer && (
                <Card
                    title="Disclaimer"
                    extra={<Button onClick={handleClose}>Close</Button>}
                >
                    <p>
                        This is a disclaimer message. Please read it carefully.
                    </p>
                    <p>Additional information...</p>
                </Card>
            )}
        </>
    );
};

export default DisclaimerCard;
