import React from "react";

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy">
            <h1>Privacy Policy</h1>
            <p>
                This is a hobby project that I created as part of my interest in
                the Equities Market and Software Development.
            </p>
            <h2>Information Collection and Usage</h2>
            <p>
                For a better experience in using the Service, I may require you
                to provide us with certain personally identifiable information,
                including but not limited to Username, Email Address, First Name
                and Last Name. The information that I request will be retained
                on your device and is not collected by me in any way.
            </p>
            <h2>Service Providers</h2>
            <p>
                Some content or applications on the Website are served by
                third-parties, including content providers, analytics providers,
                social media companies, and application providers. These third
                parties may use cookies alone or in conjunction with web beacons
                or other tracking technologies to collect information about you
                when you use our Website. The information they collect may be
                associated with your Personal Data or they may collect
                information, including Personal Data, about your online
                activities over time and across different websites and other
                online services. They may use this information to provide you
                with interest-based (behavioral) advertising or other targeted
                content.
            </p>
        </div>
    );
};

export default PrivacyPolicy;
