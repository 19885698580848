import React from "react";
import Disclaimer from "../../components/misc/Disclaimer";

const DisclaimerPage = () => {
    return (
        <div className="disclaimer-page">
            <Disclaimer />
        </div>
    );
};

export default DisclaimerPage;
