import React from "react";

const Disclaimer: React.FC = () => {
    return (
        <div>
            <h2>Disclaimer</h2>
            <p>
                Stock trading, Forex trading, or any other form of securities
                trading is extremely high risk. The information provided
                anywhere on the "AlphaSignals" website and accompanying material
                is for informational purposes only. It should not be considered
                legal or financial advice. You should consult with an attorney
                or other professional to determine what may be best for your
                individual needs. AlphaSignals does not make any guarantee or
                other promise as to any results that may be obtained from using
                our content. No one should make any investment decision without
                first consulting his or her own financial advisor and conducting
                his or her own research and due diligence. To the maximum extent
                permitted by law, AlphaSignals disclaims any and all liability
                in the event any information, commentary, analysis, opinions,
                advice and/or recommendations prove to be inaccurate, incomplete
                or unreliable, or result in any investment or other losses.
                Content contained on or made available through the website is
                not intended to and does not constitute legal, financial, or
                investment advice and no attorney-client relationship is formed.
                Your use of the information on the website or materials linked
                from the Web is at your own risk.
            </p>
        </div>
    );
};

export default Disclaimer;
