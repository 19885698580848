import React from "react";
import { ReactComponent as Logo } from "../assets/icon.svg";
import "./header.css";
import { Input } from "antd";
const { Search } = Input;

const Header = () => {
    const onSearch = (value: string) => {
        if (value === "") return;
        window.location.href = `/stock/${value}`;
    };
    return (
        <nav className="navbar">
            <div className="container">
                <a href="/" className="logo">
                    <Logo />
                </a>
                <ul className="nav-links"></ul>
                <div className="search-bar">
                    <Search
                        placeholder="Ticker"
                        enterButton
                        onSearch={onSearch}
                    />
                </div>
            </div>
        </nav>
    );
};

export default Header;
