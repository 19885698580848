import { getDoc, collection, doc } from "@firebase/firestore";
import { firestore, storage } from "../firebase";
import { ref, getDownloadURL } from "firebase/storage";

interface SignalType {
    key: React.Key;
    ticker: string;
    automated_channel_buy: number;
    automated_channel_sell: number;
    date: string;
    imageRef: string;
}

type DateObject = {
    [key: string]: {
        Lower: number;
        Upper: number;
    };
};

const findNearestFutureDate = (
    dates: DateObject,
    currentDate: string,
): string => {
    const currentDateObj: Date = new Date(currentDate);
    const validDateKeys: string[] = Object.keys(dates).filter(
        (key) => !isNaN(Date.parse(key)),
    );

    const futureDates: string[] = validDateKeys
        .filter((date) => new Date(date) > currentDateObj)
        .sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

    return futureDates.length > 0 ? futureDates[0] : ""; // Return an empty string if no future dates found
};

const getSignals = async (tickerList: string[]): Promise<SignalType[]> => {
    const firestoreRef = collection(firestore, "signals");
    let date = getUSCurrentDate();
    const queries = tickerList.map(
        (ticker) => doc(firestoreRef, ticker), // Use `doc` method to refer to documents directly by ID
    );
    const results = await Promise.all(queries.map((docRef) => getDoc(docRef)));
    const mergedResults: SignalType[] = results.reduce(
        (acc: SignalType[], docSnapshot) => {
            if (docSnapshot.exists()) {
                const data = docSnapshot.data();
                let nearestDate = findNearestFutureDate(data, date);
                if (data) {
                    acc.push({
                        ...data,
                        key: docSnapshot.id,
                        ticker: data.ticker,
                        automated_channel_buy: data[nearestDate]["Lower"],
                        automated_channel_sell: data[nearestDate]["Upper"],
                        date: data.date_stored,
                        imageRef: data.imageRef,
                    }); // Assign the document ID as 'key'
                }
            }
            return acc;
        },
        [],
    );

    return mergedResults;
};

const getImageDownloadURL = async (
    refString: string,
    handleError: (error: any) => void,
    handleSuccess: (image: string) => void,
) => {
    /**
     * @description get image download url from storage
     * @param refString: string
     * @returns string
     */
    const imageRef = ref(storage, refString);
    return await getDownloadURL(imageRef)
        .then((imageUrl: string) => handleSuccess(imageUrl))
        .catch((error) => handleError(error));
};

const getUSCurrentDate = () => {
    const currentDate = new Date().toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        timeZone: "America/New_York", // US Eastern Timezone (adjust according to the desired US timezone)
    });

    return currentDate;
};

export { getSignals, getImageDownloadURL };
