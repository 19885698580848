import React, { useRef, useEffect } from "react";
import * as d3 from "d3";

interface IProps {
    data: any[];
}

const CandlestickChart: React.FC<IProps> = ({ data }) => {
    useEffect(() => {
        if (!data || data.length === 0) return;
        const windowWidth = window.innerWidth;
        const margin = { top: 20, right: 30, bottom: 30, left: 40 };
        const width = windowWidth - margin.left - margin.right;
        const height = 400 - margin.top - margin.bottom;

        const svg = d3
            .select(chartRef.current)
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`);

        const x = d3
            .scaleBand()
            .range([0, width])
            .domain(data.map((d) => d.date))
            .padding(0.2);

        const y = d3
            .scaleLinear()
            .range([height, 0])
            .domain([d3.min(data, (d) => d.low), d3.max(data, (d) => d.high)])
            .nice();

        svg.selectAll(".stick")
            .data(data)
            .enter()
            .append("line")
            .attr("class", "stick")
            .attr("x1", (d) => x(d.date) + x.bandwidth() / 2)
            .attr("x2", (d) => x(d.date) + x.bandwidth() / 2)
            .attr("y1", (d) => y(d.high))
            .attr("y2", (d) => y(d.low))
            .attr("stroke", (d) => (d.close > d.open ? "green" : "red"))
            .attr("stroke-width", 1);

        svg.selectAll(".candle")
            .data(data)
            .enter()
            .append("rect")
            .attr("class", "candle")
            .attr("x", (d) => x(d.date))
            .attr("y", (d) => (d.open > d.close ? y(d.open) : y(d.close)))
            .attr("width", x.bandwidth())
            .attr("height", (d) =>
                d.open === d.close ? 1 : Math.abs(y(d.open) - y(d.close)),
            )
            .attr("fill", (d) => (d.close > d.open ? "green" : "red"));

        // Axes
        const xAxis = d3.axisBottom(x);
        const yAxis = d3.axisLeft(y);

        svg.append("g")
            .attr("transform", `translate(0, ${height})`)
            .call(xAxis);

        svg.append("g").call(yAxis);
    }, [data]);
    const chartRef = useRef<HTMLDivElement | null>(null);
    return <div ref={chartRef}></div>;
};

export default CandlestickChart;
