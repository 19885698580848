import React from "react";
import { Footer } from "antd/es/layout/layout";
const FooterWrapper: React.FC = () => {
    return (
        <Footer>
            <span style={{ marginRight: "10px" }}>
                <a href="/terms-of-service">Terms of Service</a>
            </span>
            <span>
                <a href="/privacy-policy">Privacy Policy</a>
            </span>
        </Footer>
    );
};

export default FooterWrapper;
