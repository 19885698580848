import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getSignals } from "../../apis/signals";
import CandleStickChart from "../../components/Charts/CandleStickChart";
import { Row, Col } from "antd";
import DisclaimerCard from "../Disclaimer/disclaimer";
interface IProps {
    ticker?: string;
}

const ComprehensiveTickerInformation: React.FC<IProps> = ({ ticker }) => {
    useEffect(() => {
        if (ticker) {
            getSignals([ticker]).then((signalList) => {
                console.log("Signal list: ", signalList);
            });
        }
    }, [ticker]);

    const testData = [
        { date: "2023-01-01", open: 100, high: 120, low: 90, close: 110 },
        { date: "2023-01-02", open: 110, high: 130, low: 100, close: 120 },
        { date: "2023-01-03", open: 120, high: 140, low: 110, close: 130 },
        { date: "2023-01-04", open: 130, high: 150, low: 120, close: 140 },
        { date: "2023-01-05", open: 140, high: 160, low: 130, close: 150 },
        // Add more
    ];

    return (
        <div>
            <DisclaimerCard />
            <h1>{ticker}</h1>
            <Row gutter={16}>
                <CandleStickChart data={testData} />
            </Row>
            <Row gutter={16}>
                <Col span={12}>Insert signals future data here...</Col>
                <Col span={12}>Insert quant data here.</Col>
            </Row>
        </div>
    );
};

const TickerInformationWrapper = () => {
    let { ticker } = useParams(); // Get the :ticker parameter from the URL
    if (typeof ticker === "string") {
        ticker = ticker.toUpperCase();
    }
    return <ComprehensiveTickerInformation ticker={ticker} />; // Pass the :ticker parameter as a prop with a default value
};

export default TickerInformationWrapper;
