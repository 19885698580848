import React from "react";
export default function ErrorPage() {
    return (
        <div>
            <p style={{ color: "red", fontSize: "30px" }}>
                404: Page Not Found
            </p>
        </div>
    );
}
