import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Router from "./pages/router";
import Header from "./shared/header";
import FooterWrapper from "./shared/footer";

const App = () => {
    return (
        <div>
            <Header />
            <BrowserRouter>
                <Router />
            </BrowserRouter>
            <FooterWrapper />
        </div>
    );
};

export default App;
