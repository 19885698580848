import React from "react";
import SignalsTable from "../../components/SignalsTable";
import "./styles.css";

const Home = () => {
    return (
        <div className="darkTheme">
            <SignalsTable tickerList={["SPY", "QQQ", "DJIA"]} />
        </div>
    );
};

export default Home;
