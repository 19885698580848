import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyANBbkgEJ-bufVB7XN0MdmjzCvgpHh_X2s",
    authDomain: "test-project-9a4e4.firebaseapp.com",
    projectId: "test-project-9a4e4",
    storageBucket: "test-project-9a4e4.appspot.com",
    messagingSenderId: "454573581714",
    appId: "1:454573581714:web:6619b40ec2ab0e86ae4d4b",
    measurementId: "G-JVQQE1TN33",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const firestore = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);
const analytics = getAnalytics(firebaseApp);
// Check if localhost development
if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
) {
    const emulatorHost = "localhost";
    const emulatorFireStorePort = 8080;
    const emulatorStoragePort = 9199;
    connectFirestoreEmulator(firestore, emulatorHost, emulatorFireStorePort);
    connectStorageEmulator(
        getStorage(firebaseApp),
        emulatorHost,
        emulatorStoragePort,
    );
}
export { firestore, storage, analytics };
