import React, { useEffect, useState } from "react";
import { getSignals } from "../apis/signals";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { TickerInformation } from "./TickerInformation";
import "./styles/styles.css";
interface SignalType {
    key: React.Key;
    ticker: string;
    automated_channel_buy: number;
    automated_channel_sell: number;
    date: string;
    imageRef: string;
}

const columns: ColumnsType<SignalType> = [
    { title: "Ticker", dataIndex: "ticker", key: "ticker" },
    {
        title: "Automated Channel Entry Signal",
        dataIndex: "automated_channel_buy",
        key: "automated_channel_buy",
    },
    {
        title: "Automated Channel Exit Signal",
        dataIndex: "automated_channel_sell",
        key: "automated_channel_sell",
    },
    {
        title: "Last Updated",
        dataIndex: "date",
        key: "date",
    },
];

interface IProps {
    tickerList: string[];
}

const SignalsTable: React.FC<IProps> = ({ tickerList }) => {
    const [signalData, setSignalData] = useState<SignalType[]>([]);
    useEffect(() => {
        getSignals(tickerList).then((signalList: SignalType[]) => {
            setSignalData(signalList);
        });
    }, [tickerList]);
    return (
        <div className="index-signal-table">
            <Table
                rowKey={(record) => record.key}
                columns={columns}
                expandable={{
                    expandedRowRender: (record) => (
                        <TickerInformation imageRef={record.imageRef} />
                    ),
                }}
                dataSource={signalData}
            />
        </div>
    );
};

export default SignalsTable;
