import React from "react";
import { Route, Routes } from "react-router-dom";
import { routerType } from "../routes.types";
import pagesData from "./pagesData";

const Router = () => {
    const pageRoutes = pagesData.map(
        ({ key, path, title, element }: routerType) => {
            return <Route key={key} path={`/${path}`} element={element} />;
        },
    );

    return <Routes>{pageRoutes}</Routes>;
};

export default Router;
